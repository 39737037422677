
import nerdesinService from "../../services/nerdesin-service";

export function loadPersonelList({ commit }) {
  nerdesinService.getPersonelList().then(response => {
            commit("SETPERSONELLIST",response.data);
        })
        .catch(e => {
            console.log(e);
          //console.log(e);
        });
}
