
import nerdesinService from "../../services/nerdesin-service";

export function loadViewPositionList({ commit }) {
  nerdesinService.getViewPosition().then(response => {
            commit("SETPOSITIONVIEWLIST",response.data);
        })
        .catch(e => {
            console.log(e);
        });
}

/*

SELECT 
*
 from view_personel
 left join tbl_position psz ON psz.POSPRID = view_personel.PRCID
 left join tbl_place on tbl_place.PLCID = psz.POSPLID
 WHERE view_personel.PRCACTIVE=1 ORDER BY psz.POSSTAT





export function addDealer({ commit, getters }, payload) {
  softwareService.addDealer(payload).then(response => {
    loadDealerList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

export function updateDealer({ commit, getters }, payload) {
  softwareService.updateDealer(payload).then(response => {
    loadDealerList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

export function delDealer({ commit, getters }, payload) {
  softwareService.delDealer(payload).then(response => {
    loadDealerList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}
*/