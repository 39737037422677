
import nerdesinService from "../../services/nerdesin-service";

export function loadPlaceList({ commit }) {
  nerdesinService.getPlaceList().then(response => {
            commit("SETPLACELIST",response.data);
        })
        .catch(e => {
            console.log(e);
          //console.log(e);
        });
}
