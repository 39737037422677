import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/style.css"



const VueApp=createApp(App);


VueApp.use(store);
VueApp.use(router);
VueApp.mount('#app');

