
<template>
    <popup-modal ref="popup" style="padding:20px;" >
    <div class="dlgContent">
    <h2 style="margin-top: 0">{{ title }}</h2>
        <img src="/cancel.png" id="cancelButton" @click="_cancel"/>
        <p>{{ message }}</p>
        <div class="content" style="margin:0px;width:100%;padding:10px;">
        <div class="row" >
            <button v-if="_showOfficeButton()" class="col thinButton" @click="_setStatePlace(1,0)" style="width:47%;background-color: #0e8f55;color:#fff;">{{ ofisButtonCaption }}</button>
            <button v-if="_showFinishButton()" class="col thinButton" @click="_setStatePlace(0,0)" style="width:47%;background-color: #c7a719 ;color:#fff;">Mesai Bitti</button>

            <!--<button class="col-md-6 thinButton" @click="_cancel" style="background-color: #333;color:#fff;width:47%;float:right;">{{ cancelButton }}</button>-->
            
        </div>
        <div class="row text-center" id="placeListDiv" style="text-align:center;">
            <button class="col-md-2 thinButton" style="margin: 9px;" @click="_setStatePlace(2,listItem.PLCID)"  v-for="(listItem) in placeList" >{{ listItem.PLCNAME }}</button>


        </div>

        </div>
        </div>
    </popup-modal>
</template>
<script>
import nerdesinService from '../services/nerdesin-service'
import PopupModal from './PopupModal.vue'

export default {
    name: 'SetplaceDialogueV2',

    components: { PopupModal },

    data: () => ({
        // Parameters that change depending on the type of dialogue
        ofisButtonCaption:"Mesai Başlıyor",
        placeList:[],
        title: undefined,
        message: undefined, // Main text content
        okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
        cancelButton: 'İptal', // text for cancel button
        personID:0,
        curState:0,
        
        
        
        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),

    created() {
        this.$store.dispatch("place/loadPlaceList");
        this.placeList=this.$store.state.place.placeList;
    },
    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.personID = opts.personID
            this.curState=opts.curState;
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
            
        },

        _showOfficeButton(){
            if(this.curState==1) return false;
            if(this.curState==0) this.ofisButtonCaption="Mesai Başlıyor";
            if(this.curState==2) this.ofisButtonCaption="Yerime geçiyorum";
            return true;
        },
        _showFinishButton(){
            if(this.curState==0) return false;
            return true;
        },

        _setStatePlace(stateID,placeID) {
            switch(stateID){

                case 0:{
                    nerdesinService.setViewPositionV2FinishWork(this.personID).then(response => {
                        this.$emit('listUpdated', response.data);
                    })
                    .catch(e => {
                    console.log(e);
                    });                    
                }break;
                case 1:{
                    nerdesinService.setViewPositionV2StartWork(this.personID).then(response => {
                        this.$emit('listUpdated', response.data);
                        })
                    .catch(e => {
                    console.log(e);
                    });
                }break;
                case 2:{
                    nerdesinService.setViewPositionV2GoForWork(this.personID,placeID).then(response => {
                        this.$emit('listUpdated', response.data);
                        })
                    .catch(e => {
                    console.log(e);
                    });
                }break;

            }
            this.$refs.popup.close()
            this.resolvePromise(true)
            
        },

        _cancel() {
            console.log("cancel");
            this.$refs.popup.close()
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User cancelled the dialogue'))
            
        },
    },
}
</script>
<style scoped>
.btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
#placeListDiv{
    max-height:300px;
    height: auto;
    overflow-y:auto;
    overflow-x:hidden;
    


}
.dlgContent{
    float: left;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}

#cancelButton{
    position:absolute;
    top:1px;
    right:3px;
    z-index: 10000;
    cursor: pointer;
}





.ok-btn {
    padding: 0.5em 1em;
    background-color: #d5eae7;
    background-color: #f00;
    color: #35907f;
    color: #fff;
    border: 2px solid #0ec5a4;
    border: 2px solid #900;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;

    /*color: red;*/
    text-decoration: none;
    line-height: 2.5rem;
    cursor: pointer;
}

.cancel-btn {
    padding: 0.5em 1em;
    background-color: #d5eae7;
    color: #35907f;
    border: 2px solid #0ec5a4;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
}
</style>