import { createStore } from 'vuex'
import personel  from './personel'
import place  from './place'
import positionview  from './positionview'
import auth from "./auth"


import createPersistedState from 'vuex-persistedstate'
export default createStore({
  /*
  state: {
  },
  mutations: {
  },
  actions: {
  },
  */
  modules: {
    personel,
    place,
    positionview,
    auth,
  },
  plugins: [createPersistedState()],
})
