<template>
    <div class="row m-3">
        <div class="col-md-3 card-body m-3">
            <h2>Kullanıcılar</h2>
            <div class="row" style="margin:20px;">
                <div class="col-md-12">
                    <div class="listBox users">
                        <div class="listItem"  v-for="(user,index) in userList" :key="user.id" v-on:dblclick="selectUser(index)">
                            {{ user.name }}[{{ user.role }}]<a class="delButton" @click="delUser(index)"><i class="fa fa-trash"></i></a>          
                        </div>
                    </div>                        
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="userName">Name</label>
                            <input type="hidden" id="id" name="id" class="form-control" v-model="user.id" />
                            <input type="text" name="userName" class="form-control" v-model="user.name" placeholder="name" />
                        </div>
                        <div class="form-group col-md-6">
                            <label for="role">Role</label>
                            <select name="role" id="role" class="form-control" v-model="user.role">
                                <option value="su">Superuser</option>
                                <option value="visitor">Visitor</option>
                            </select>
                        </div>
                        
                        <div class="form-group col-md-6">
                            <label for="email">E-Posta</label>
                            <input type="text" name="email" class="form-control" v-model="user.email" placeholder="email" />
                        </div>

                        <div class="form-group col-md-6">
                            <label for="password">Password</label>
                            <input type="password" name="password"  class="form-control" v-model="user.password"  placeholder="password"/>        
                        </div>

                    </div>
                    <div class="row">
                        <button type="button" class="button btn-success" v-on:click="newUser()">Reset</button>                
                        <button type="button" class="button btn-success float-right" v-on:click="saveUser()">Save</button>                
                    </div>

                </div>
                
            </div>

        </div>
        <div class="col-md-3 card-body m-3">
            <h2>Yerler</h2>
            <div class="row" style="margin:10px;">
                <div class="col-md-12">
                    <div class="listBox places">
                        <div class="listItem" v-for="(place,index) in placeList" :key="place.PLCID" v-on:dblclick="selectPlace(index)">
                            <div class="itemAP">
                                {{ (place.PLCACTIVE==1) ? "(A)":"&nbsp;" }}
                            </div>
                            <div class="itemContent">
                                {{   place.PLCNAME  }}
                            </div>
                            <div class="itemButton">
                                <a class="delButton" @click="delPlace(index)"><i class="fa fa-trash"></i></a>
                            </div>

                        </div>
                    </div>                        
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="place">Place</label>
                            <input type="hidden" id="id" name="id" class="form-control" v-model="place.id" />
                            <input type="text" name="place" class="form-control" v-model="place.name" />
                        </div>
                        <div class="form-group col-md-6">
                            <label for="active">&nbsp;</label>
                            <select name="active" id="active" class="form-control" v-model="place.active">
                                <option value="1">Active</option>
                                <option value="0">Passive</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <button type="button" class="button btn-success" v-on:click="newPlace()">Reset</button>                
                        <button type="button" class="button btn-success float-right" v-on:click="savePlace()">Save</button>                
                    </div>

                </div>
            </div>

        </div>
        <div class="col-md-3 card-body m-3">
            <h2>Personel</h2>
            <div class="row" style="margin:10px;">
                <div class="col-md-12">
                    <div class="listBox personels">
                        <div class="listItem"  v-for="(personel,index) in personList" :key="personel.PRCID"  v-on:dblclick="selectPerson(index)">
                            <div class="itemAP">
                                {{ (personel.PRCACTIVE==1) ? "(A)":"&nbsp;" }}
                            </div>
                            <div class="itemContent">
                                {{ personel.PRCNAME }}
                            </div>
                            <div class="itemButton">
                                <a class="delButton" @click="delPerson(index)"><i class="fa fa-trash"></i></a>    
                            </div>
                        </div>
                    </div>                        
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="nick">Nick</label>
                            <input type="hidden" id="id" name="id" class="form-control" v-model="personel.id" />
                            <input type="text" name="nick" class="form-control" v-model="personel.nick" />
                        </div>

                        <div class="form-group col-md-6">
                            <label for="name">Name</label>
                            <input type="text" name="name" class="form-control" v-model="personel.name" />
                        </div>

                        <div class="form-group col-md-6">
                            <label for="pswd">Password</label>
                            <input type="text" name="pswd" class="form-control" v-model="personel.pswd" />
                        </div>

                        <div class="form-group col-md-6">
                            <label for="active">&nbsp;</label>
                            <select name="active" id="active" class="form-control" v-model="personel.active">
                                <option value="1">Active</option>
                                <option value="0">Passive</option>
                            </select>
                        </div>

                    </div>
                    <div class="row">
                        <button type="button" class="button btn-success" v-on:click="newPerson()">Reset</button>                
                        <button type="button" class="button btn-success float-right" v-on:click="savePerson()">Save</button>                
                    </div>

                </div>
            </div>
            
        </div>
    </div>
    <div id="">
    </div>
</template>

<script>
import nerdesinService from '../services/nerdesin-service';


    export default {
        name: 'Settings',
        data() {
            return {
                userList:[],
                placeList:[],
                personList:[],
                user:{
                    id:0,
                    name:"",
                    email: "",
                    password: "",
                    role:"visitor"
                },
                place:{
                    id:0,
                    name: "",
                    active:1,
                },
                personel:{
                    id:0,
                    nick:"",
                    name: "",
                    pswd: "",
                    active:1,
                },

            }
        },
        methods: {
            /*** USER */
            newUser(){
                this.user.id=0;
                this.user.name="";
                this.user.role="visitor";
                this.user.email="";
                this.user.password="";
            },

            selectUser(index){
                this.user.id=this.userList[index].id;
                this.user.name=this.userList[index].name;
                this.user.role=this.userList[index].role;
                this.user.email=this.userList[index].email;
                this.user.password="*******";
            },
            saveUser(){
              nerdesinService.addUser(
                this.user.id,
                this.user.name,
                this.user.email,
                this.user.password,
                this.user.role
              ).then(response => {
                    this.userList=response.data;
                    this.newUser();
                })
                .catch(e => {
                    console.log(e);
                //console.log(e);
                });  
            },
            delUser(index){
              nerdesinService.delUser(this.userList[index].id).then(response => {
                    this.userList=response.data;
                })
                .catch(e => {
                    console.log(e);
                //console.log(e);
                });  
            },
            fullUserList(){
              nerdesinService.getUserList().then(response => {
                    this.userList=response.data;
                    this.newUser();
                })
                .catch(e => {
                    console.log(e);
                //console.log(e);
                });  
            },
            /*** PLACE */
            newPlace(){
                this.place.id=0;
                this.place.name="";
                this.place.active=1;
            },
            isPlaceChecked(){
                return this.place.active ? true:false;
            },
            selectPlace(index){
                this.place.id=this.placeList[index].PLCID;
                this.place.name=this.placeList[index].PLCNAME;
                this.place.active=this.placeList[index].PLCACTIVE;
            },
            savePlace(){
              nerdesinService.addPlace(
                this.place.id,
                this.place.name,
                this.place.active
              ).then(response => {
                    this.placeList=response.data;
                    this.newPlace();
                })
                .catch(e => {
                    console.log(e);
                //console.log(e);
                });  
            },
            delPlace(index){
              nerdesinService.delPlace(this.placeList[index].PLCID).then(response => {
                    this.placeList=response.data;
                })
                .catch(e => {
                    console.log(e);
                //console.log(e);
                });  
            },

            fullPlaceList(){
              nerdesinService.getPlaceFullList().then(response => {
                    this.placeList=response.data;
                })
                .catch(e => {
                    console.log(e);
                //console.log(e);
                });  
            },
            /*** PERSON */
            newPerson(){
                this.personel.id=0;
                this.personel.nick="";
                this.personel.name="";
                this.personel.pswd="";
                this.personel.active=1;
            },
            isPersonChecked(){
                return this.personel.active;
            },
            selectPerson(index){
                this.personel.id=this.personList[index].PRCID;
                this.personel.nick=this.personList[index].PRCNICK;
                this.personel.name=this.personList[index].PRCNAME;
                this.personel.pswd="";
                this.personel.active=this.personList[index].PRCACTIVE;
            },
            savePerson(){
              nerdesinService.addPerson(
                this.personel.id,
                this.personel.nick,
                this.personel.name,
                this.personel.pswd,
                this.personel.active
              ).then(response => {
                    this.personList=response.data;
                    this.newPerson();
                })
                .catch(e => {
                    console.log(e);
                //console.log(e);
                });  
            },
            delPerson(index){
              nerdesinService.delPerson(this.personList[index].PRCID).then(response => {
                    this.personList=response.data;
                })
                .catch(e => {
                    console.log(e);
                //console.log(e);
                });  
            },
            fullPersonList(){
              nerdesinService.getPersonelFullList().then(response => {
                    this.personList=response.data;
                })
                .catch(e => {
                    console.log(e);
                //console.log(e);
                });  
            },

        },
        mounted(){
            this.fullUserList();
            this.fullPlaceList();
            this.fullPersonList();
    
        },
    }

</script>

<style scoped>
    #login {
        width: 500px;
        border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        margin: auto;
        margin-top: 200px;
        padding: 20px;
    }

    label,
    input:not(type="checkbox"){
        width: 100%;
        margin-left:auto;
        margin-right:auto;
    }

     .card-body{
        display: block;
        background-color: #ccc;
        border:#bbb 1px solid;
        padding: 5px;
     }

    .listBox{
        position: relative;
        display: block;
        width: 100%;
        height:300px;
        background-color: #fff;
        border:#ddd 1px solid;
        overflow: auto;
    }
    .listItem{
        float:left;
        position: relative;
        text-align: left ;
        margin:0px !important;
        padding: 5px;
        width: 100%;
    }

    .listItem:hover{
        background-color:#3eaf7c !important;
        color:#fff;
        cursor: pointer;
    }
    .listItem:nth-child(even){
        background-color:#eee;
    }

    .listItem .itemAP{
        float: left;
        width:40px !important;
    }
    .listItem .itemContent{
        float: left;
    }
    .listItem .itemButton{
        float: right;
        width:40px !important;
    }

    .form-group label{
        text-align:left !important;
        font-weight: bold;
    }
    .form-group{
        margin-top: 15px;
        
    }

    .button{
        margin: 10px;
        width: 120px;
    }

    .delButton{
        float:right;
        color:red;
        cursor: pointer;
    }

</style>