<template>
    <div id="login">
        <h1>Login</h1>
        <div class="row">
            <div class="form-group">
                <input type="text" name="name" class="form-control" v-model="name" placeholder="name" />
            </div>
            <div class="form-group">
                <input type="password" name="password"  class="form-control" v-model="password"  placeholder="password"/>        
            </div>
            <div class="form-group">
                <button type="button" class="button btn-success" v-on:click="login()">Login</button>                
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isValidUserV2 } from '../store/auth/actions';

    export default {
        name: 'LoginV2',
        data() {
            return {
                    name: "",
                    password: ""
            }
        },
        methods: {
            ...mapActions('auth', ['isValidUserV2']),            
            login() {
            const { name, password } = this;
                if(this.name != "" && this.password != "") {
                        if(!this.isValidUserV2({name,password})){
                        console.log("The username and / or password is incorrect");
                    }
                } else {
                    console.log("A username and password must be present");
                }
            }
        }
    }
</script>

<style scoped>
    #login {
        width: 500px;
        border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        margin: auto;
        margin-top: 200px;
        padding: 20px;
    }
    .form-group{
        margin:5px;
    }
    .button{

    }
</style>