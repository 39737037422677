import axios from 'axios';

const cgtAxios = (dynamicBaseURL) => {
  // axios instance for making requests
  const axiosInstance = axios.create({
    baseURL: dynamicBaseURL
  });
  
  axiosInstance.interceptors.request.use(config => {
    //NProgress.start()
    return config
  })
  
  // before a response is returned stop nprogress
  axiosInstance.interceptors.response.use(response => {
   // NProgress.done()
    return response
  })


  return axiosInstance;
};

export default cgtAxios;