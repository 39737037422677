<template>
<!-- Start Footer Area -->
<footer class="mt-5 mb-5">
    <!-- -->
</footer>
<!-- /End Footer Area -->
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>
<style scoped lang="scss">
/*=============================
	20. Start Footer CSS
===============================*/
</style>