import cgtAxios from "../cgt-axios";
import http from "../http-common";

class NerdesinService {
  // http:// 192.168.1.36:7600
  // https://ner desin-api.basoz.net/
  // http://api .localhost/
  // https://api. localhost/basoz-nerdesin/
  // Check MAC

  checklogin(email, password) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect
      .post(`/login.php`, { email: email, password: password })
      .then((response) => {
        return response.data;
      });
  }
  checkLoginV2(name, password) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect
      .post(`/loginv2.php`, { name: name, password: password })
      .then((response) => {
        return response.data;
      });
  }

  // User(s)
  getUserList() {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/userlist.php`);
  }

  // Place(s)
  getPersonelFullList() {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/personelfulllist.php`);
  }

  getPersonelList() {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/personellist.php`);
  }

  // Place(s)
  getPlaceFullList() {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/placefulllist.php`);
  }

  getPlaceList() {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/placelist.php`);
  }

  // Position View
  getViewPosition() {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/getviewposition.php`);
  }

  getViewPositionV2(id) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/getviewpositionV2.php`, { id: id });
  }

// V1
  setViewPosition(id, st, plc) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/setposition.php`, { id: id, st: st, plc: plc });
  }

  setViewPosition(id, st) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/setposition.php`, { id: id, st: st, plc: plc });
  }

  setViewPositionFinishWork(id) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/setposition.php`, { id: id, st: 0, plc: 0 });
  }

  setViewPositionStartWork(id) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/setposition.php`, { id: id, st: 1, plc: 0 });
  }

  setViewPositionGoForWork(id, plc) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/setposition.php`, { id: id, st: 2, plc: plc });
  }
//V2

setViewPositionV2(id, st, plc) {
  const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
  return axiosConnect.post(`/setpositionV2.php`, { id: id, st: st, plc: plc });
}

setViewPositionV2(id, st) {
  const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
  return axiosConnect.post(`/setpositionV2.php`, { id: id, st: st, plc: plc });
}

setViewPositionV2FinishWork(id) {
  const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
  return axiosConnect.post(`/setpositionV2.php`, { id: id, st: 0, plc: 0 });
}

setViewPositionV2StartWork(id) {
  const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
  return axiosConnect.post(`/setpositionV2.php`, { id: id, st: 1, plc: 0 });
}

setViewPositionV2GoForWork(id, plc) {
  const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
  return axiosConnect.post(`/setpositionV2.php`, { id: id, st: 2, plc: plc });
}

  // Person V2 2
  addPerson(id, nick, name, pswd, active) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/personeladd.php`, {
      id: id,
      nick: nick,
      name: name,
      pswd: pswd,
      active: active,
    });
  }
  addPersonV2(name, pswd) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/personeladdV2.php`, {
      name: name,
      pswd: pswd,
    });
  }

  delPerson(id) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/del.php`, { id: id, type: "person" });
  }

  addPlace(id, name, active) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/placeadd.php`, {
      id: id,
      name: name,
      active: active,
    });
  }

  delPlace(id) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/del.php`, { id: id, type: "place" });
  }

  addUser(id, name, email, password, role) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/useradd.php`, {
      id: id,
      name: name,
      email: email,
      password: password,
      role: role,
    });
  }

  delUser(id) {
    const axiosConnect = cgtAxios("https://nerdesin-api.basoz.net/");
    return axiosConnect.post(`/del.php`, { id: id, type: "user" });
  }

  /*
  get(id) {
    return http.post(`/view/product.php`,{ pId: id });
  }

  newCustomer(dataForm) {
    return http.post(`/view/register.php`,dataForm);
  }
*/
  /*  getAllBayi() {
    return http.get("/view/categories.php");
  }

  getAllUser() {
    return http.get("/view/categories.php");
  }
*/
  /*
  create(data) {
    return http.post("/tutorials", data);
  }

  update(id, data) {
    return http.put(`/tutorials/${id}`, data);
  }

  delete(id) {
    return http.delete(`/tutorials/${id}`);
  }

  deleteAll() {
    return http.delete(`/tutorials`);
  }

  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`);
  }
  */
}

export default new NerdesinService();
