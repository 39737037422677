<template>
  <app-header v-show="isLogged()"></app-header>
   <main class='content' :style='mainContentStyle'>
      <router-view/>
    <app-footer></app-footer>
    
  </main>
</template>
<script>

import AppHeader from "./_layouts/Header.vue"
import AppFooter from "./_layouts/Footer.vue"
import PreLoader from "./_layouts/preloader.vue"
import { mapState, mapActions } from 'vuex'


export default {
    name: 'app',
    components:{
         'app-header':AppHeader,
         'app-footer':AppFooter,
         'pre-loader':PreLoader
     },
    computed: {
        ...mapState({
            alert: state => state.alert
        }),
        mainContentStyle(){
          let styleText = '';
          styleText = '';

          return styleText;

        }
    },
    mounted() {
      this.logged=false;
      var x=JSON.parse(localStorage.getItem("neredeyimmiv2"));
        if(x) {
            console.log(x);
            this.$store.commit('auth/SETAUTHUSER',x);
            //store.commit("SETAUTHUSER", x );
            //Store.modules.app.mutations.SETAUTHUSER(Store.modules.app.state,x);
            this.logged=true;
            this.$router.replace({ name: "home" });
            
        }
    },
    methods: {
      isLogged(){
        return this.$store.state.auth.isAuthenticated;
      }
        /*...mapState('auth', ['loggedIn']),
        isLoggedIN(){
          this.isLoggedIn=this.loggedIn;
          return false;
        },
        */

    },
/*
    watch: {
        $route (to, from){
            // clear alert on location change
            this.clearAlert();
        }

    } 
    */  
     
  }
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.content{
  min-height: 100vh;
  min-height: 50vh;
  
}

</style>
