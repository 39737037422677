
export function SETPOSITIONVIEWLIST(state, val) {
    state.positionViewList = val
/*    
    state.disarda=[];
    state.ofisIcinde=[];
    state.ofisDisinda=[];
    for (let index = 0; index < state.positionViewList.lenght; index++) {
        if (val[index].POSSTAT == 0) {
            state.disarda.push(val[index]);
        }
    }
    for (let index = 0; index < state.positionViewList.lenght; index++) {
        if (val[index].POSSTAT == 1) {
            state.ofisIcinde.push(val[index]);
        }
    }
    for (let index = 0; index < state.positionViewList.lenght; index++) {
        if (val[index].POSSTAT == 2) {
            state.ofisDisinda.push(val[index]);
        }
    }
*/    
}
