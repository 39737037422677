export function authId(state) {
    return state.id;
}

export function authUser(state) {
    return state.user;
}

export function authRole(state) {
    return state.role;
}


export function authFlag(state) {
    return state.isAuthenticated;
}
