<template>
    <div class="row m-3">
        
        <div class="col-md-3 card-body m-3" style="max-width: 400px;">
            <h2>Person Update</h2>
            <div class="row" style="margin:10px;">
                <div class="col-md-12">
                    <div class="row">

                        <div class="form-group col-md-12">
                            <label for="pswd">Password</label>
                            <input type="text" name="pswd" class="form-control" v-model="personel.pswd" />
                        </div>

                    </div>
                    <div class="row">
                        <button type="button" class="button btn-success float-right" v-on:click="savePersonV2()">Save</button>                
                    </div>
                    <div v-if="hasProcess & hasProcessResult">It's Done</div>
                    <div v-if="hasProcess & !hasProcessResult">Not Saved, Try Again!</div>

                </div>
            </div>
            
        </div>
    </div>
    <div id="">
    </div>
</template>

<script>
import nerdesinService from '../services/nerdesin-service';


    export default {
        name: 'Profile',
        data() {
            return {
                hasProcess:false,
                hasProcessResult:false,
                personel:{
                    name: this.$store.state.auth.user,
                    pswd: "",
                },

            }
        },
        methods: {

            savePersonV2(){
              nerdesinService.addPersonV2(
                this.personel.name,
                this.personel.pswd,
              ).then(response => {
                    this.personList=response.data;
                    this.hasProcess=true;
                    this.hasProcessResult=true;

                })
                .catch(e => {
                    this.hasProcess=true;
                    this.hasProcessResult=false;
                    console.log(e);
                //console.log(e);
                });  
            },

        },
        mounted(){
    
        },
    }

</script>

<style scoped>
    #login {
        width: 500px;
        border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        margin: auto;
        margin-top: 200px;
        padding: 20px;
    }

    label,
    input:not(type="checkbox"){
        width: 100%;
        margin-left:auto;
        margin-right:auto;
    }

     .card-body{
        display: block;
        background-color: #ccc;
        border:#bbb 1px solid;
        padding: 5px;
     }

    .listBox{
        position: relative;
        display: block;
        width: 100%;
        height:300px;
        background-color: #fff;
        border:#ddd 1px solid;
        overflow: auto;
    }
    .listItem{
        float:left;
        position: relative;
        text-align: left ;
        margin:0px !important;
        padding: 5px;
        width: 100%;
    }

    .listItem:hover{
        background-color:#3eaf7c !important;
        color:#fff;
        cursor: pointer;
    }
    .listItem:nth-child(even){
        background-color:#eee;
    }

    .listItem .itemAP{
        float: left;
        width:40px !important;
    }
    .listItem .itemContent{
        float: left;
    }
    .listItem .itemButton{
        float: right;
        width:40px !important;
    }

    .form-group label{
        text-align:left !important;
        font-weight: bold;
    }
    .form-group{
        margin-top: 15px;
        
    }

    .button{
        margin: 10px;
        width: 120px;
    }

    .delButton{
        float:right;
        color:red;
        cursor: pointer;
    }

</style>